export const NODES_LIST: {
  id: string;
  name: langData;
  flag: string;
  /** !!!!!!!! НИ В КОЕМ СЛУЧАЕ НЕ МЕНЯТЬ ТИП ДАННЫХ, ИНАЧЕ ВСЯ СИСТЕМА СЛОМАЕТСЯ С ПОЛНОЙ ПОТЕРЕЙ ДАННЫХ */
  protocols: ('VLESS' | 'SS' | 'HTTP' | 'HTTPS')[];
  type: 'marzban' | 'proxy';
  host: string;
}[] = [
  {
    id: 'aeza-stockholm',
    name: 'locationStockholm',
    flag: '🇸🇪',
    protocols: ['VLESS'],
    type: 'marzban',
    host: 's1.rocketman-vpn.com',
  },
  {
    id: 'aeza-stockholm-http',
    name: 'locationStockholm',
    flag: '🇸🇪',
    protocols: ['HTTP', 'HTTPS'],
    type: 'proxy',
    host: 's1.rocketman-vpn.com',
  },
];
