import React from 'react';
import { BasePage } from './base';

const exampleSubscribeData = {
  proxies: {
    vless: {
      id: '71d71a5e-2739-48f7-8737-97d50635f92d',
      flow: 'xtls-rprx-vision',
    },
    shadowsocks: {
      password: 'a0KDjQxpTk5uirc7YE5rzQ',
      method: 'chacha20-ietf-poly1305',
    },
  },
  expire: 1734008422,
  data_limit: 10737418240,
  data_limit_reset_strategy: 'day',
  sub_updated_at: '2024-12-15T14:29:49.300422',
  sub_last_user_agent:
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36',
  online_at: null,
  username: 'XanderWP-light_4',
  status: 'expired',
  used_traffic: 0,
  lifetime_used_traffic: 0,
  created_at: '2024-12-11T13:00:22.256300',
  links: [
    'vless://71d71a5e-2739-48f7-8737-97d50635f92d@s1.rocketman-vpn.com:443?security=reality&type=tcp&headerType=&flow=xtls-rprx-vision&path=&host=&sni=cdn.jsdelivr.net&fp=chrome&pbk=WovQE0n3kB51195inMsf1vBa2ih2Yy4AzcFZyKvVtlA&sid=ecdfdcc4089edecc#%F0%9F%91%A4%20XanderWP-light_4%20%F0%9F%9A%80%20RocketMan-VPN.com',
    'ss://Y2hhY2hhMjAtaWV0Zi1wb2x5MTMwNTphMEtEalF4cFRrNXVpcmM3WUU1cnpR@s1.rocketman-vpn.com:1080#%F0%9F%91%A4%20XanderWP-light_4%20%F0%9F%9A%80%20RocketMan-VPN.com',
  ],
  subscription_url: '/sub/WGFuZGVyV1AtbGlnaHRfNCwxNzM0Mjc0MDMzynZ00tYkQG',
};

export class SubscribePage extends BasePage<
  {
    data: typeof exampleSubscribeData;
  },
  {}
> {
  state = {
    data: exampleSubscribeData,
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <section style={{ marginTop: '10px' }}>
          <div className="container subscribeData">
            name: {this.state.data.username}
          </div>
        </section>
      </>
    );
  }
}
