import React from 'react';
import { langString, getAllLangs, langData } from '../lang';
import { PROJECT_BOT, PROJECT_NAME } from '../config/project';
import { LandingPage } from './pages';
import { PaymentPage } from './pages/payment';
import { TermPage } from './pages/term';
import { ContactsPage } from './pages/contacts';
import { SubscribePage } from './pages/subscribe';

const state = {
  loaded: false,
  page: 'index' as 'index' | 'payment' | 'term' | 'contacts' | 'subscribe',
  lang: 'en' as langType,
  fix_amount: 0,
  fix_id: '',
};

export class App extends React.Component<{}, typeof state> {
  state = { ...state };
  LangString(key: langData, ...args: any[]) {
    return langString(this.state.lang, key, ...args);
  }

  ChangeLang(lang: langType) {
    this.setState({ lang });
    localStorage.setItem('lang', lang);
  }

  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    const url = new URL(location.href);
    if (
      url.searchParams.has('lang') &&
      getAllLangs().includes(url.searchParams.get('lang') as any)
    ) {
      this.ChangeLang(url.searchParams.get('lang') as any);
    } else {
      const oldLang = localStorage.getItem('lang');
      if (oldLang && getAllLangs().includes(oldLang as any)) {
        this.ChangeLang(oldLang as any);
      }
    }

    this.setState({
      loaded: true,
      page: url.searchParams.has('payment')
        ? 'payment'
        : url.searchParams.has('term')
          ? 'term'
          : url.searchParams.has('subscribe')
            ? 'subscribe'
            : url.searchParams.has('contacts')
              ? 'contacts'
              : 'index',
      fix_amount: url.searchParams.get('amount')
        ? parseInt(url.searchParams.get('amount') as string)
        : 0,
      fix_id: url.searchParams.get('id') || '',
    });
  }

  render = () => {
    if (!this.state.loaded) {
      return <></>;
    }
    return (
      <>
        <header>
          <div className="container">
            <nav>
              <div
                className="logo"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ page: 'index' });
                }}
              >
                🚀 {PROJECT_NAME}
              </div>
              <div className="right">
                <a
                  href={`https://t.me/${PROJECT_BOT}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                >
                  <b>{this.LangString('landingTGBotButton')}</b>
                </a>
                <select
                  onChange={e => {
                    e.preventDefault();
                    this.ChangeLang(e.currentTarget.value as any);
                  }}
                  value={this.state.lang}
                >
                  {getAllLangs().map(q => {
                    return (
                      <option key={q} value={q}>
                        {q}
                      </option>
                    );
                  })}
                </select>
              </div>
            </nav>
          </div>
        </header>
        <section id="hero">
          <div className="container">
            <h1>{this.LangString('landingTitle')}</h1>
            <p>{this.LangString('landingTitleDesc')}</p>
          </div>
        </section>
        {this.state.page === 'index' ? (
          <LandingPage lang={this.state.lang} />
        ) : (
          <></>
        )}
        {this.state.page === 'payment' ? (
          <PaymentPage
            lang={this.state.lang}
            usrID={this.state.fix_id}
            usrAmount={this.state.fix_amount}
          />
        ) : (
          <></>
        )}
        {this.state.page === 'term' ? (
          <TermPage lang={this.state.lang} />
        ) : (
          <></>
        )}
        {this.state.page === 'contacts' ? (
          <ContactsPage lang={this.state.lang} />
        ) : (
          <></>
        )}
        {this.state.page === 'subscribe' ? (
          <SubscribePage lang={this.state.lang} />
        ) : (
          <></>
        )}
        <footer>
          <div className="container">
            <p>© 2024 {PROJECT_NAME}</p>
            <span className="links">
              <a href="/?term" target="_blank" rel="noopener noreferrer">
                {this.LangString('terms')}
              </a>
              <a href="/?contacts" target="_blank" rel="noopener noreferrer">
                {this.LangString('contacts')}
              </a>
            </span>
            <a
              href="https://rocketman-streams.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.LangString('landingFooterText')}
            </a>

            <p className="footerLicense">
              GAMETTI LTD, Registration No. 14348659, 2nd Floor, College House,
              17 King Edwards Road, Ruislip, London
            </p>
          </div>
        </footer>
      </>
    );
  };
}
