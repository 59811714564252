import { System } from '../utils/System';

export const CURRENCY_LIST = {
  USD: 1,
  UAH: 40,
  RUB: 100,
};
export const CURRENCY_LIST_ICON = {
  USD: '$',
  UAH: '₴',
  RUB: '₽',
};

export type CurrencyName = keyof typeof CURRENCY_LIST;
export const AllCurrencyList = Object.keys(CURRENCY_LIST) as CurrencyName[];

export const CurrencyConvert = (
  amountInUSD: number,
  currency: CurrencyName
) => {
  const diff = CURRENCY_LIST[currency];
  if (!diff) return 0;
  return amountInUSD * diff;
};
export const CurrencyConvertToUSD = (
  amount: number,
  currency: CurrencyName
) => {
  const diff = CURRENCY_LIST[currency];
  if (!diff) return 0;
  return amount / diff;
};

export const CurrencyConvertText = (
  amountInUSD: number,
  currency: CurrencyName,
  symbol = false
) => {
  return `${symbol ? `${CURRENCY_LIST_ICON[currency]}` : ''}${System.numberFormat(parseFloat(CurrencyConvert(amountInUSD, currency).toFixed(2).replace('.00', '')))}${!symbol ? ` ${currency}` : ''}`;
};
