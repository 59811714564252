import { CurrencyConvert, CurrencyConvertToUSD } from './currency';

export const PROJECT_NAME = 'RocketMan VPN';
export const PROJECT_BOT = 'rocketman_vpn_bot';

export const WEB_PAYMENT = false;
export const PAYMENT_METHOD = 'morune' as 'morune' | 'morune2' | 'freekassa';
export const PAYMENT_MIN_SUM = CurrencyConvertToUSD(100, 'RUB');

export const PAYMENT_METHODS_LIST: {
  id: typeof PAYMENT_METHOD;
  name: langData;
  desc?: langData;
  limitMethods?: string[];
  sbpDirect?: boolean;
  email?: boolean;
}[] = [
  {
    id: 'morune2',
    name: 'paymentMethodMorune2',
    limitMethods: ['sbp'],
    sbpDirect: true,
  },
  // {
  //   id: 'morune',
  //   name: 'paymentMethodMorune',
  // },
  // {
  //   id: 'freekassa',
  //   name: 'paymentMethodFreekassa',
  //   email: true,
  // },
];
